/* Modal */

.modal {
  position: fixed;
  color: black;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #0000009c;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s /*cubic-bezier(0.84, 2.1, 1,-0.73)*/;
}

.modal--show {
  opacity: 1;
  pointer-events: unset;
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 40%;
  max-height: 300px;
  min-height: 300px;
  min-width: 400px;
  max-width: 600px;
  width: 40%;
  border-radius: 9px;
  background-color: white;
}

.modal-content {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
}

.modal-titulo {
  font-size: 2em;
}

.modal-social-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
}

.modal-social {
  display: flex;
  align-items: center;
  gap: 15px;
}

.social-links:hover {
  color: blue;
}

.modal-copyicon {
  cursor: pointer;
  transition: all 0.1s;
}

.modal-copyicon:hover {
  color: green;
  transform: scale(1.2);
}

/* Header */

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
}

.header-titulo {
  font-family: "Shantell Sans", sans-serif;
  font-weight: normal;
}

.header-img {
  height: 100px;
}

ul {
  display: flex;
  gap: 15px;
  list-style: none;
}

ul li {
  font-weight: bold;
  transition: all 0.2s;
}

ul li:hover {
  transform: translateY(5px);
}

.botones-header {
  display: flex;
  gap: 13px;
  align-items: center;
}

.boton-contacto,
.boton-cerrar {
  padding: 8px 15px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  border: none;
  border-radius: 5px;
  background-color: #c4c4c4;
  transition: all 0.2s;
  cursor: pointer;
}

.boton-contacto:hover,
.boton-cerrar:hover {
  background-color: #dedddd;
}

.dark-mod-boton,
.boton-idioma {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: solid #c4c4c4 1px;
  border-radius: 5px;
  background-color: transparent;
  /* transition: all 0.7s!important; */
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.dark-mod-boton:hover,
.boton-idioma:hover {
  /* transform: rotate(360deg); */
  color: red !important;
}

.languages-container {
  display: none;
  border-radius: 5px;
  margin-top: 5px;
  height: 95px;
  width: 65px;
  border: solid #c4c4c4 1px;
  position: absolute;
  background-color: transparent;
}

.idiomas--open {
  display: flex;
  justify-content: center;
  align-items: center;
}

.idiomas {
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  margin-left: 9px;
  align-items: flex-start;
}

.img-idioma {
  height: 15px;
  width: 23px;
  border-radius: 2px;
}

.idioma {
  display: flex;
  background-color: transparent;
  border: none;
  font-weight: bolder;
  border-radius: 5px;
  gap: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.idioma:hover {
  transform: rotateY(25deg);
}

.hamburguesa {
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.linea {
  width: 45px;
  height: 5px;
  transition: all 0.3s ease-in-out;
  background-color: black;
  border-radius: 5px;
}

.hamburguesa--show .header-titulo {
  color: white;
}

.hamburguesa--show .linea:nth-child(1) {
  transform: translateY(5px) rotate(45deg);
}

.linea:nth-child(2) {
  animation: slide-in 0.3s;
}

.hamburguesa--show .linea:nth-child(2) {
  opacity: 0;
  animation: slide-out 0.3s;
}

.hamburguesa--show .linea:nth-child(3) {
  transform: translateY(-15px) rotate(-45deg);
}

/* Inicio */

#inicio {
  height: 90vh;
  justify-content: space-between;
}

.inicio-container {
  display: grid;
  width: 20rem;
  gap: 20px;
}

.inicio-logo {
  height: 250px;
  width: 250px;
  border-radius: 50px;
}

.inicio-text {
  text-align: start;
}

.inicio-titulo {
  display: flex;
  justify-content: space-between;
  font-size: 50px;
  font-family: "Shantell Sans", sans-serif;
}

.saludar:hover {
  animation: saludo-animacion 0.5s;
  cursor: pointer;
}

.inicio-subtitulo {
  font-size: 30px;
}

.inicio-boton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: #2dc4e7;
  padding: 15px;
  font-weight: bold;
  font-size: 20px;
  border: solid black;
  border-radius: 15px;
  transition: background-color 0.5s, color 0.5s;
  cursor: pointer;
}

.inicio-boton:hover {
  background-color: #000000;
  color: white;
}

.span-icono {
  display: flex;
}

.icono-curriculum {
  transition: transform 0.5s;
}

.inicio-boton:hover .icono-curriculum {
  transform: translateX(10px);
}

/* About Me */

#aboutme {
  height: 35%;
  flex-direction: column;
  justify-content: space-around;
}

.aboutme-container {
  border: solid 2px #2dc4e7;
  font-size: 19px;
  padding: 20px;
  place-content: space-around;
  display: grid;
  text-align: justify;
  border-radius: 10px;
  height: 50%;
}

/* Proyectos */

#proyectos {
  height: 50%;
  flex-direction: column;
  justify-content: space-around;
  border-bottom: 2px solid #c4c4c4;
}

/* Footer */

footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 17px;
  gap: 25px;
}

.footer-titulo {
  font-weight: bold;
  font-size: 20px;
  font-family: "Shantell Sans";
}

.footer-buttons {
  display: flex;
  gap: 15px;
}

.footer-boton {
  padding: 10px;
  font-size: 15px;
  display: flex;
  align-items: center;
  border: none;
  background-color: #f0f0f0;
  border-radius: 5px;
  gap: 8px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.footer-boton:hover {
  color: white;
}

.footer-boton:nth-child(1):hover {
  background-color: #161414;
}

.footer-boton:nth-child(2):hover {
  background-color: #f41268;
}

.footer-boton:nth-child(3):hover {
  background-color: #505257;
}

.footer-boton:nth-child(4):hover {
  background-color: #9147ff;
}

/* Keyframes */

@keyframes saludo-animacion {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(-10deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Media Query */

@media screen and (min-width: 769px) {
  .hamburguesa {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  nav {
    display: none;
  }

  .boton-contacto,
  .boton-idioma,
  .dark-mod-boton {
    display: none;
  }

  nav.show-nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    animation: slide-in 0.5s ease-in-out;
  }

  .header-titulo {
    z-index: 1;
  }

  .botones-header {
    position: absolute;
    bottom: 0;
    width: 100%;
    right: 0;
    left: 0;
    display: flex;
    margin-bottom: 15px;
    justify-content: center;
  }

  button.show-btn {
    display: flex;
    animation: slide-in 0.9s ease-in-out;
  }

  ul {
    align-items: flex-start;
    flex-direction: column;
    font-size: 3rem;
  }

  ul li:hover {
    transform: translateX(5px);
  }

  .languages-container {
    padding: 15px;
    bottom: 100%;
    left: 0;
    right: 0;
    height: auto;
    width: auto;
    border: none;
  }

  .idiomas {
    flex-direction: row;
    margin: 0;
  }
}

@media screen and (max-width: 735px) {
  #inicio {
    flex-direction: column;
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 1000px) {
  #aboutme {
    height: 25%;
  }
}

@media screen and (max-width: 590px) {
  header {
    padding-bottom: 50px;
  }

  #inicio {
    gap: 50px;
    padding: 5px 0px 35px 0px;
  }

  #aboutme {
    height: 30%;
  }

  .aboutme-container {
    height: auto;
  }
}

@media screen and (max-width: 1000px) {
  #proyectos {
    height: 55%;
  }
}

@media screen and (min-width: 1500px) {
  #proyectos {
    height: 55%;
  }
}

@media screen and (max-width: 600px) {
  .footer-boton {
    padding: 13px;
  }

  .footer-boton span {
    display: none;
  }
}
